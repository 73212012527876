import React from 'react';

function DotFlashing(props) {
    return (<>
        <span
            className="dot-flashing"
        />
    </>);
}

export default DotFlashing;
